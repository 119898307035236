<template>
  <div>
    <!--begin::Resource-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 v-if="edit_form" class="card-label">Resource Edit</h3>
          <h3 v-else class="card-label">Resource Add</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="ma-2 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToResourceList()">
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_resource_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-text-field
                  v-model="resource.name"
                  :rules="nameRules"
                  label="Resource Name"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-row>

              <v-row
                class="justify-end"
                v-if="edit_form"
                style="margin-top: 10px !important;margin-bottom: 15px !important;"
              >
                <a @click="downloadResource(resource.resource_path)" download>
                  Download Existing Resource
                </a>
              </v-row>
              <v-row>
                <v-file-input
                  show-size
                  label="Resource file"
                  ref="file"
                  outlined
                  @change="changeValidation()"
                  dense
                  v-model="resource.resource_file"
                >
                </v-file-input>
              </v-row>

              <v-row>
                <v-text-field
                  label="Resource URL"
                  outlined
                  dense
                  @change="changeValidation()"
                  :rules="urlRules"
                  v-model="resource.url"
                >
                </v-text-field>
              </v-row>

              <v-row align="center">
                <v-select
                  v-model="resource.assign_to"
                  :items="company_user_list"
                  label="Assign Person"
                  :rules="[v => !!v || 'Assign Person is required']"
                  dense
                  outlined
                >
                </v-select>
              </v-row>

              <v-row>
                <v-combobox
                  multiple
                  dense
                  outlined
                  v-model="resource.selected_tags"
                  label="Tags"
                  append-icon
                  chips
                  deletable-chips
                  :items="tags"
                  class="tag-input"
                  @keyup.tab="updateTags"
                >
                </v-combobox>
             </v-row>
             <v-row>
                <v-select
                  v-model="resource.resource_type"
                  :items="resource_type_list"
                  label="Resource Type"
                  :rules="[v => !!v || 'Resource Type is required']"
                  dense
                  outlined
                >
                </v-select>
                <v-tooltip bottom v-if="resource_type_list_count == 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="margin-top: -30px;"
                      color="grey lighten-1"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span>Please add Resource Type from Admin Section > Resource Type</span>
                </v-tooltip>
              </v-row>

               <v-row>
                <v-textarea
                  v-model="resource.note"
                  label="Note"
                  dense
                  outlined
                ></v-textarea>
              </v-row>

              <v-row>
                <v-btn
                  v-if="edit_form"
                  :disabled="!valid || disable_btn"
                  color="success"
                  class="mr-4"
                  ref="kt_store_submit"
                  @click="validateEditForm"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Update
                </v-btn>
                <v-btn
                  v-else
                  :disabled="!valid || disable_btn"
                  color="success"
                  class="mr-4 ml-0"
                  ref="kt_store_submit"
                  @click="validate"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Save
                </v-btn>
              </v-row>
            </v-form>
          </div>
        </form>
      </div>
    </div>
    <!--end::Resource-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_TAGS } from "@/core/services/store/tag.module";
import { GET_RESOURCE_TYPES } from "@/core/services/store/resource.module";
import { GET_COMPANY_USERS } from "@/core/services/store/user.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

export default {
  name: "resourceCreate",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      resource: {
        name: "",
        resource_file: [],
        selected_tags: [],
        resource_type: "",
        resource_files: "",
        resource_path: "",
        assign_to: "",
        url: "",
        note: ""
      },
      menu1: false, //use for date
      urlRules: [
        v => !!v || "Resource URL or Resource file is required",
        v => /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g.test(
            v
          ) || "Resource URL must be valid"
      ],
      fileRules: [
        value =>
          !!value || "Resource URL or Resource file is required",
        value =>value.size < 5000000 ||
          "File size should be less than 5 MB!"
      ],
      valid: true,
      disable_btn: false,
      nameRules: [v => !!v || "Name is required"],
      tags: ["here", "there"],
      resource_types: [],
      resource_type_list: [],
      resource_type_list_count: 0,
      search: "", //sync search,
      edit_form: false,
      company_user_list: []
    };
  },
  props: {
    resource_id: String
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["usersTags", "resourceTypes"])
  },
  mounted() {
    let context = this;
    if (this.$route.params.id) {
      context.edit_form = true;
      axios({
        method: "get",
        url: "resource/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.resource.name = result.data.resource.name;
          context.resource.note = result.data.resource.note;
          context.resource.url = result.data.resource.url;
          context.resource.resource_type = result.data.resource.resource_type;
          context.resource.selected_tags = result.data.resource.selected_tags;
          context.resource.resource_path = result.data.resource.resource_path;
          context.resource.assign_to = result.data.resource.assign_user;
        },
        function(error) {
          Swal.fire("Error", "Resource not found.", "error");
          context.$router.push({ name: "resourceList" });
        }
      );
    }

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Resource" }]);
    // this.getTags();
    this.refreshTags();
    this.getResourceTypes();
    this.getCompanyUsers();

    const resource_form = KTUtil.getById("kt_resource_form");

    this.fv = formValidation(resource_form, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "Name is required"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    changeValidation() {
      if(this.resource.url != ""){
        this.fileRules = [
          value =>value.size < 5000000 ||
            "File size should be less than 5 MB!"
        ];
      } else {
        this.fileRules = [
          value =>
            !!value || "Resource URL or Resource file is required",
          value =>value.size < 5000000 ||
            "File size should be less than 5 MB!"
        ];
      }
      if(this.resource.resource_file != [] && this.resource.resource_file) {
        if(this.resource.url != ""){
          this.urlRules = [
            v => /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g.test(
                v
              ) || "Resource URL must be valid"
          ];
        } else {
          this.urlRules = [];
        }
      } else {
        this.urlRules = [
          v => !!v || "Resource URL or Resource file is required",
          v => /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g.test(
              v
            ) || "Resource URL must be valid"
        ];
      }

    },
    downloadResource() {
      let url =
        process.env.VUE_APP_DOC_URL +
        "api/downloadResource/" +
        this.$route.params.id;
      window.open(url);
    },
    getCompanyUsers() {
      let context = this;
      this.$store.dispatch(GET_COMPANY_USERS).then(() => {
        this.company_users = this.companyUsers;
        if (this.company_users) {
          this.company_users.forEach(user => {
            this.company_user_list.push(user.first_name + " " + user.last_name);
          });
        } else {
          axios({
            method: "get",
            url: "get-company-users",
            baseURL: process.env.VUE_APP_ACBACK_URL
          }).then(function(result) {
            if (result.data.company_users) {
              result.data.company_users.forEach(user => {
                context.company_user_list.push(
                  user.first_name + " " + user.last_name
                );
              });
            }
          });
        }
      });
    },
    updateResource() {
      var context = this;
      var formData = new FormData();
      formData.append("resource_file", this.resource.resource_file);
      formData.append("note", this.resource.note);
      formData.append("url", this.resource.url);
      formData.append("name", this.resource.name);
      formData.append("tags", this.resource.selected_tags);
      formData.append("res_type", this.resource.resource_type);
      formData.append("assign_to", this.resource.assign_to);
      //put/patch request return blank array as form data.
      axios
        .post("resource/" + this.$route.params.id, formData)
        .then(() => {
          Swal.fire("Updated", "Resource updated successfully", "success");
        })
        .then(() => {
          context.refreshTags();
          context.$router.go(-1);
          context.disable_btn = false;
        },
        function(error) {
          Swal.fire("Error", "Resource not found.", "error");
          context.$router.push({ name: "resourceList" });
        }
        );
    },
    redirectToResourceList() {
      var context = this;
      context.$router.go(-1);
    },
    getResourceTypes() {
      this.$store.dispatch(GET_RESOURCE_TYPES).then(() => {
        this.resource_types = this.resourceTypes;

        this.resource_types.forEach(resource_type => {
          this.resource_type_list.push({
            text: resource_type.type,
            value: resource_type.type
          });
          this.resource_type_list_count += 1;
        });
      });
    },
    getTags() {
      this.$store.dispatch(GET_TAGS).then(() => {
        this.tags = this.usersTags;
      });
    },
    refreshTags() {
      let context = this;
      axios({
        method: "post",
        url: "get-tags",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(function(result) {
        if (result.data.tags) {
          context.tags = result.data.tags;
        }
      });
    },
    submitResource() {
      var context = this;
      var formData = new FormData();
      formData.append("resource_file", this.resource.resource_file);
      formData.append("name", this.resource.name);
      formData.append("url", this.resource.url);
      formData.append("note", this.resource.note);
      formData.append("tags", this.resource.selected_tags);
      formData.append("resource_type", this.resource.resource_type);
      formData.append("assign_to", this.resource.assign_to);
      axios.post("resource", formData).then(
        function(result) {
          context.refreshTags();
          context.$router.go(-1);
          Swal.fire("Success", result.data.message, "success");
          context.disable_btn = false;
        },
        function() {
          Swal.fire("Error", "Error in save resource", "error");
          context.disable_btn = false;
        }
      );
    },
    updateTags() {
      this.$nextTick(() => {
        this.resource.selected_tags.push(this.search);
        this.resource.selected_tags.pop();
      });
    },
    validateEditForm() {
      let context = this;
      context.disable_btn = true;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        this.updateResource();
      } else {
        this.snackbar = true;
      }
    },
    validate() {
      let context = this;
      context.disable_btn = true;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        this.submitResource();
      } else {
        this.snackbar = true;
        context.disable_btn = false;
      }
    },
    remove(item) {
      this.resource.selected_tags.splice(this.tags.indexOf(item), 1);
      this.tags = [...this.tags];
    }
  }
};
</script>
